import React from "react";
import { ChevronDown } from "lucide-react";

const FeatureHeader = () => {
  return (
    <section className="bg-black pt-24 pb-12 relative overflow-hidden">
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 left-1/4 w-1/2 h-1/2 bg-blue-500/5 blur-[80px]" />
        <div className="absolute bottom-0 right-1/4 w-1/2 h-1/2 bg-blue-500/5 blur-[80px]" />
      </div>

      <div className="container mx-auto px-4 relative z-10 transform scale-75 md:scale-90 lg:scale-100 origin-top-left">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            <span className="text-white">DISCOVER BETMATE'S </span>
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600">
              POWERFUL SOCIAL BETTING FEATURES
            </span>
            <span className="ml-1">⚡</span>
          </h2>
          <p className="text-gray-400 text-base md:text-lg mb-8 max-w-xl mx-auto">
            Join BetMate and elevate your betting experience with a suite of
            features that bring social interaction and community engagement to
            every bet.
          </p>
          <div className="relative h-10 mt-4">
            <div className="absolute left-1/2 transform -translate-x-1/2 animate-bounce">
              <div className="relative">
                <ChevronDown
                  className="w-6 h-6 text-blue-500 opacity-20 absolute top-0 left-0"
                  style={{ animation: "pulse 2s infinite" }}
                />
                <ChevronDown
                  className="w-6 h-6 text-blue-400 opacity-40 absolute top-1 left-0"
                  style={{ animation: "pulse 2s infinite 0.5s" }}
                />
                <ChevronDown
                  className="w-6 h-6 text-blue-300 opacity-60 absolute top-2 left-0"
                  style={{ animation: "pulse 2s infinite 1s" }}
                />
                <ChevronDown
                  className="w-6 h-6 text-white opacity-80"
                  style={{ animation: "pulse 2s infinite 1.5s" }}
                />
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 w-full h-px bg-gradient-to-r from-transparent via-blue-500/50 to-transparent" />
        </div>
      </div>
    </section>
  );
};

export default FeatureHeader;
