import React, { useState, useEffect } from "react";
import { Typewriter } from "react-simple-typewriter";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronUp, ChevronDown, PauseCircle, PlayCircle } from "lucide-react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSwipeable } from "react-swipeable";

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const words = ["Simple", "Secure", "Free"];

  const images = [
    "Image-03.png",
    "Image-04.png",
    "Image-05.png",
    "Image-06.png",
    "Image-07.png",
  ];
  const SLIDE_DURATION = 6000;

  useEffect(() => {
    let interval;
    if (isAnimating) {
      interval = setInterval(() => {
        handleNext();
      }, SLIDE_DURATION);
    }
    return () => clearInterval(interval);
  }, [isAnimating]);

  const handleNext = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="w-full min-h-screen bg-gradient-to-b from-black via-gray-900 to-black overflow-hidden">
      {/* Main Content */}
      <main className="w-full min-h-screen pt-14 md:pt-16">
        <div className="container mx-auto px-4 md:px-6 py-8 md:py-12">
          {/* Coming Soon Banner */}
          <div className="w-full text-center py-2 mb-6 md:mb-8 rounded">
            <p className="text-white text-sm md:text-base font-semibold">
              🚀 Our App is Coming Soon! Stay tuned
            </p>
          </div>

          <div className="flex flex-col items-center lg:flex-row justify-between gap-6 md:gap-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="text-center lg:text-left lg:w-1/2 w-full px-4 sm:px-0"
            >
              <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold mb-2 md:mb-4">
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="text-white"
                >
                  Bet
                </motion.span>
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="text-[#1E88E5]"
                >
                  Mate
                </motion.span>
              </h1>
              <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold mb-3 md:mb-6 flex justify-center lg:justify-start">
                <span className="mr-1 text-white">We are</span>
                <span className="text-[#1E88E5]">
                  <Typewriter
                    words={words}
                    loop={0}
                    cursor
                    cursorStyle="_"
                    typeSpeed={100}
                    deleteSpeed={50}
                    delaySpeed={1500}
                  />
                </span>
              </h2>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="text-sm sm:text-base md:text-lg text-gray-300 mb-4 md:mb-8"
              >
                Create, join, and personalize your bets with BetMate. Connect,
                predict, and earn just from your social impact while making
                every game more engaging.
              </motion.p>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
                className="flex flex-col sm:flex-row items-center sm:justify-center lg:justify-start space-y-3 sm:space-y-0 sm:space-x-4 md:space-x-6"
              >
                <a
                  href="#"
                  className="relative group"
                  data-tooltip-id="apple-tooltip"
                >
                  <img
                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                    alt="Download on the Apple Store"
                    className="w-24 sm:w-28 md:w-36 transform transition-transform duration-300 group-hover:scale-105"
                  />
                </a>
                <a
                  href="#"
                  className="relative group"
                  data-tooltip-id="google-tooltip"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                    alt="Get it on Google Play"
                    className="w-24 sm:w-28 md:w-36 transform transition-transform duration-300 group-hover:scale-105"
                  />
                </a>
              </motion.div>
            </motion.div>

            <div className="lg:w-1/2 w-full px-4 sm:px-0">
              <div className="flex flex-col items-center gap-4 md:gap-6">
                <div className="relative w-full max-w-xs sm:max-w-sm md:max-w-md mx-auto">
                  <div
                    className="absolute inset-0 rounded-2xl"
                    style={{
                      background:
                        "radial-gradient(circle at center, rgba(30,136,229,0.2) 0%, transparent 70%)",
                      filter: "blur(15px)",
                      transform: "translateY(15px) scale(0.85)",
                    }}
                  />
                  <div
                    className="relative rounded-2xl overflow-hidden backdrop-blur-sm"
                    style={{
                      boxShadow: `
                        0 20px 40px -10px rgba(30,136,229,0.25),
                        0 0 0 1px rgba(255,255,255,0.1),
                        0 0 25px rgba(30,136,229,0.1) inset
                      `,
                    }}
                    {...handlers}
                  >
                    <div
                      className="w-full h-full relative"
                      style={{ minHeight: "250px" }}
                    >
                      <AnimatePresence mode="wait" initial={false}>
                        <motion.img
                          key={currentImageIndex}
                          src={images[currentImageIndex]}
                          alt={`BetMate Preview ${currentImageIndex + 1}`}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{
                            duration: 0.8,
                            ease: "easeInOut",
                          }}
                          className="absolute top-0 left-0 w-full h-full object-contain"
                        />
                      </AnimatePresence>
                    </div>
                  </div>
                  {/* Rest of the component remains the same */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Tooltip
        id="apple-tooltip"
        place="top"
        content="Download on the Apple Store"
      />
      <Tooltip
        id="google-tooltip"
        place="top"
        content="Get it on Google Play"
      />
      <Tooltip
        id="carousel-tooltip"
        place="top"
        content={isAnimating ? "Pause" : "Play"}
      />
    </div>
  );
};

export default Home;