// PromotionSection.jsx

import React, { useState, useRef, useEffect } from "react";
import { PauseCircle, PlayCircle } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const PromotionSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const autoPlayRef = useRef(null);

  const promotions = [
    {
      emoji: "🎉",
      title: "FIRST BET REWARD",
      subtitle: "START STRONG",
      description:
        "Create your first bet and unlock a special reward to kick off the excitement!",
      image: "/1.PNG",
    },
    {
      emoji: "🥇",
      title: "FIRST WIN BONUS",
      subtitle: "CELEBRATE YOUR SUCCESS",
      description:
        "Enjoy an extra 10% MateCoins bonus on your first win on BetMate.",
      image: "/10.PNG",
    },
  ];

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      if (autoPlayRef.current) {
        clearInterval(autoPlayRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isAutoPlay) {
      autoPlayRef.current = setInterval(() => {
        setActiveIndex((prev) => (prev + 1) % promotions.length);
      }, 5000);
    }

    return () => {
      if (autoPlayRef.current) {
        clearInterval(autoPlayRef.current);
      }
    };
  }, [isAutoPlay, promotions.length]);

  const calculateRotation = (mousePosition) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const centerX = viewportWidth / 2;
    const centerY = viewportHeight / 2;
    const deltaX = mousePosition.x - centerX;
    const deltaY = mousePosition.y - centerY;
    const rotationX = (deltaY / viewportHeight) * 10;
    const rotationY = -(deltaX / viewportWidth) * 10;
    return { x: rotationX, y: rotationY };
  };

  return (
    <section className="bg-black py-16 md:py-20 relative overflow-hidden">
      <div className="absolute inset-0 pointer-events-none">
        <div
          className="absolute inset-0 opacity-20"
          style={{
            backgroundImage: `
              radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, 
              rgba(37, 99, 235, 0.2) 0%, 
              transparent 60%)
            `,
          }}
        />
      </div>

      <div className="absolute top-4 right-4 z-10">
        <button
          onClick={() => setIsAutoPlay(!isAutoPlay)}
          className="p-2 rounded-full hover:bg-blue-500/20 transition-all duration-300 backdrop-blur-sm"
        >
          {isAutoPlay ? (
            <PauseCircle className="text-blue-400" size={24} />
          ) : (
            <PlayCircle className="text-blue-400" size={24} />
          )}
        </button>
      </div>

      <div className="container mx-auto px-4 md:px-6 relative overflow-hidden transform scale-75 md:scale-100 origin-top-left">
        <div className="relative">
          <AnimatePresence mode="wait">
            {promotions.map(
              (promo, index) =>
                index === activeIndex && (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }}
                    transition={{
                      type: "spring",
                      stiffness: 100,
                      damping: 20,
                      duration: 0.5,
                    }}
                    className="px-2 md:px-4"
                  >
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 items-center">
                      <div
                        className="lg:pr-6 p-6 md:p-8 rounded-2xl relative transform-gpu transition-all duration-300
                        hover:shadow-2xl hover:shadow-blue-600/10"
                        style={{
                          background:
                            "linear-gradient(169.44deg, rgba(58, 129, 191, 0.08) 1.85%, rgba(65, 48, 90, 0.08) 98.72%)",
                          borderTop: "1px solid rgba(99, 102, 241, 0.1)",
                          borderLeft: "1px solid rgba(99, 102, 241, 0.1)",
                        }}
                      >
                        <div className="relative z-10">
                          <div className="inline-flex items-center justify-center w-14 h-14 rounded-2xl bg-blue-600/10 mb-4">
                            <span className="text-3xl">{promo.emoji}</span>
                          </div>
                          <h2 className="text-3xl md:text-4xl font-bold leading-tight mb-2 md:mb-3">
                            <span className="text-white">{promo.title} </span>
                            <span className="block text-gray-400 mt-1 text-xl md:text-2xl">
                              {promo.subtitle}
                            </span>
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600 text-base md:text-lg mt-3 block">
                              {promo.description}
                            </span>
                          </h2>
                        </div>
                      </div>
                      <div className="relative h-64 md:h-80 w-full flex items-center justify-center perspective-1000">
                        <motion.div
                          className="w-[70%] h-[70%] relative transform-gpu"
                          animate={{
                            rotateX: calculateRotation(mousePosition).x,
                            rotateY: calculateRotation(mousePosition).y,
                          }}
                          transition={{
                            type: "spring",
                            stiffness: 100,
                            damping: 20,
                          }}
                        >
                          <img
                            src={promo.image}
                            alt={promo.title}
                            className="w-full h-full object-contain rounded-2xl shadow-[0_0_10px_rgba(37,99,235,0.3)] transition-all duration-300"
                          />
                          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-transparent rounded-2xl" />
                        </motion.div>
                      </div>
                    </div>
                  </motion.div>
                )
            )}
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default PromotionSection;
