import React from "react";
import { CheckCircle2, XCircle } from "lucide-react";

const ComparisonSection = () => {
  const differences = [
    {
      betmate: "Rewards for social engagement and contributions",
      bookie: "No incentives for social interaction or engagement",
    },
    {
      betmate: "Real-time chat with other users and betting hosts",
      bookie: "No direct communication or social features",
    },
    {
      betmate: "Flexible betting options with peer-to-peer trust",
      bookie: "Rigid betting options with all control held by the house",
    },
    {
      betmate: "Community support for learning and strategy sharing",
      bookie: "Lacks community-driven support or strategic insights",
    },
    {
      betmate: "Easily accessible AI-powered betting insights",
      bookie: "No AI assistance; users rely on limited house data",
    },
    {
      betmate: "Option to rebet on popular existing markets",
      bookie: "Bets are one-time only; no option to follow others",
    },
    {
      betmate: "Earn bonuses when others rebet on your markets",
      bookie: "No bonuses for individual users; house keeps all profits",
    },
    {
      betmate: "Decentralized market control in users’ hands",
      bookie: "Centralized market control, fully owned by the house",
    },
    {
      betmate: "Dynamic engagement with followers and reputation building",
      bookie: "No features for following or reputation growth",
    },
    {
      betmate: "Flexible market entry with lower minimums",
      bookie: "High minimum bets set by the house",
    },
    {
      betmate: "Freedom to cash out winnings at any time",
      bookie: "Cashing out may be limited or restricted by the house",
    },
    {
      betmate: "Rewards based on user impact, not just wins",
      bookie: "Rewards are limited to winning only, no impact recognition",
    },
    {
      betmate: "User-controlled transparency and fair-play incentives",
      bookie: "Lack of transparency with hidden house advantages",
    },
  ];

  return (
    <section className="bg-black py-16 relative overflow-hidden">
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-blue-500/5 blur-[80px]" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-blue-500/5 blur-[80px]" />
        <div className="absolute top-1/2 left-1/4 w-1/3 h-1/3 bg-purple-500/5 blur-[70px]" />
      </div>

      <div className="container mx-auto px-4 relative z-10 transform scale-75 md:scale-90 lg:scale-100 origin-top-left">
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="p-4 rounded-xl bg-gradient-to-r from-blue-500/10 via-blue-500/5 to-transparent border border-blue-500/20 hover:border-blue-400/30 transition-all duration-300">
              <h3 className="text-2xl font-bold text-center">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 animate-gradient">
                  BetMate
                </span>
              </h3>
            </div>
            <div className="p-4 rounded-xl bg-gradient-to-r from-gray-500/10 via-gray-500/5 to-transparent border border-gray-500/20 hover:border-gray-400/30 transition-all duration-300">
              <h3 className="text-2xl font-bold text-center text-gray-400">
                Bookie
              </h3>
            </div>
          </div>
          <div className="space-y-3">
            {differences.map((difference, index) => (
              <div
                key={index}
                className="grid grid-cols-2 gap-3 group hover:transform hover:scale-[1.01] transition-all duration-300 ease-out"
              >
                <div className="p-4 rounded-xl bg-gradient-to-r from-blue-500/10 via-blue-500/5 to-transparent border border-blue-500/20 flex items-center space-x-3 group-hover:border-blue-400/30 group-hover:shadow-md group-hover:shadow-blue-500/5 transition-all duration-300">
                  <CheckCircle2 className="w-5 h-5 text-blue-500 flex-shrink-0 group-hover:scale-110 transition-transform duration-300" />
                  <p className="text-gray-300 group-hover:text-white text-sm transition-colors duration-300">
                    {difference.betmate}
                  </p>
                </div>
                <div className="p-4 rounded-xl bg-gradient-to-r from-gray-500/10 via-gray-500/5 to-transparent border border-gray-500/20 flex items-center space-x-3 group-hover:border-gray-400/30 group-hover:shadow-md group-hover:shadow-gray-500/5 transition-all duration-300">
                  <XCircle className="w-5 h-5 text-gray-500 flex-shrink-0 group-hover:scale-110 transition-transform duration-300" />
                  <p className="text-gray-400 group-hover:text-gray-300 text-sm transition-colors duration-300">
                    {difference.bookie}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComparisonSection;