import React, { useState } from "react";
import {
  ChevronDown,
  TrendingUp,
  Target,
  Lightbulb,
  Info,
  Users,
  Repeat,
} from "lucide-react";

const FeatureSection = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [expandedCard, setExpandedCard] = useState(null);

  const features = [
    {
      id: 1,
      title: "Bet Creation",
      icon: <TrendingUp className="w-6 h-6" />,
      description:
        "Create and publish your own betting markets with customizable odds.",
      note: "With BetMate, users can create and manage their own betting markets by adding fixtures and setting odds for outcomes like 'Home Win,' 'Draw,' or 'Away Win.' If the required stake for creating a bet is higher than what you can afford, BetMate allows you to collaborate and split the cost with others, enabling joint ownership of the bet and shared profits or losses. Additionally, if you're unsure about setting odds or selecting fixtures, BetMate offers the option to copy active bets created by other users. Simply give your copied bet a new name, allocate your desired amount, and start—while the original creator earns a bonus for their contribution. This feature streamlines the process and makes betting accessible to everyone.",
    },
    {
      id: 2,
      title: "Exploring Betting Markets",
      icon: <Target className="w-6 h-6" />,
      description:
        "Explore and engage with a variety of user-created betting markets.",
      note: "Discover a diverse range of betting markets crafted by different users on BetMate. View fixtures, odds types, and allocated amounts to find the best opportunities for your wagers. With detailed insights into each market, you can make informed decisions and place bets on multiple markets to enhance your betting strategy.",
    },
    {
      id: 5,
      title: "Peer-to-Peer (P2P) Betting",
      icon: <Users className="w-6 h-6" />,
      description:
        "Create and join personalized bets with friends or the community.",
      note: "BetMate's P2P feature allows users to set up bets on specific fixtures, choose outcomes, and set stakes, inviting others to join. Challenge friends or open the bet to the community and see who takes the wager. Whether you win or lose, payouts are only exchanged between those who participate, making each bet more personal and engaging.",
    },
    {
      id: 3,
      title: "Prediction Creation",
      icon: <Lightbulb className="w-6 h-6" />,
      description:
        "Create and share your own predictions or follow expert insights.",
      note: "BetMate allows users to create and publish their own predictions, guiding others and showcasing their expertise. Engage with the community through comments, likes, and subscriptions, and earn rewards for your social impact. Whether creating your own or leveraging community predictions, this feature enhances your strategic betting experience.",
    },
    {
      id: 4,
      title: "Creating Betting Tips",
      icon: <Info className="w-6 h-6" />,
      description: "Share valuable tips and insights to guide fellow bettors.",
      note: "BetMate empowers users to share their expertise by creating tips on upcoming games, potential bets, and league highlights. Recommend matches to watch, teams to back, and strategies to consider for the week. Engage with the community by sharing your knowledge and learning from others to enhance your betting experience.",
    },
    {
      id: 6,
      title: "Rebet",
      icon: <Repeat className="w-6 h-6" />,
      description:
        "Easily follow and place wagers on existing bets from other users.",
      note: "BetMate's Rebet feature allows users to place bets by mirroring existing wagers made by other users. Browse through the Rebet page to find active bets that are still accepting wagers. Simply allocate the amount you wish to stake, and if the original betting market has sufficient funds to cover potential payouts, your rebet will be accepted. This makes it easy for users who may not know how to create their own bets or who prefer to follow the strategies of others. Additionally, the original creator of the bet receives a bonus when their wager is rebetted, fostering a collaborative and rewarding environment.",
    },
  ];

  const handleCardClick = (id) => {
    setExpandedCard(expandedCard === id ? null : id);
  };

  return (
    <section className="bg-black py-24 relative overflow-hidden">
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-blue-500/5 blur-[120px]" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-blue-500/5 blur-[120px]" />
      </div>

      <div className="container mx-auto px-4 relative z-10 transform scale-75 md:scale-100 origin-top-left">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature) => (
            <div
              key={feature.id}
              className="group relative"
              onMouseEnter={() => setHoveredCard(feature.id)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <div
                className={`
                  absolute inset-0 rounded-2xl
                  bg-gradient-to-r from-blue-500/30 to-transparent
                  transition-opacity duration-500
                  ${hoveredCard === feature.id ? "opacity-100" : "opacity-0"}
                  blur-xl
                `}
              />

              <div className="relative bg-black rounded-2xl border border-white/10 overflow-hidden backdrop-blur-sm">
                <div className="p-6">
                  <div className="flex justify-between items-start mb-4">
                    <div
                      className={`
                        relative group-hover:scale-110 transform transition-all duration-500
                      `}
                    >
                      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-transparent blur-xl" />
                      <div className="relative p-3 rounded-xl bg-gradient-to-r from-blue-500/10 to-transparent text-blue-400">
                        {feature.icon}
                      </div>
                    </div>

                    <button
                      onClick={() => handleCardClick(feature.id)}
                      className="text-blue-400 hover:text-blue-300 transition-colors duration-300 p-2"
                    >
                      <ChevronDown
                        className={`
                          w-5 h-5 transition-transform duration-500
                          ${expandedCard === feature.id ? "rotate-180" : ""}
                        `}
                      />
                    </button>
                  </div>

                  <h3 className="text-xl font-semibold mb-3">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-400">
                      {feature.title}
                    </span>
                  </h3>

                  <p className="text-blue-400/80 text-sm font-medium tracking-wide">
                    {feature.description}
                  </p>

                  <div
                    className={`
                      grid transition-all duration-500 ease-in-out
                      ${
                        expandedCard === feature.id
                          ? "grid-rows-[1fr] mt-4"
                          : "grid-rows-[0fr] mt-0"
                      }
                    `}
                  >
                    <div className="overflow-hidden">
                      <div className="p-4 rounded-xl bg-gradient-to-r from-blue-500/5 to-transparent border border-blue-500/10">
                        <p className="text-gray-400 text-sm leading-relaxed">
                          {feature.note}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`
                    absolute inset-0 rounded-2xl
                    bg-gradient-to-r from-blue-500/10 to-transparent
                    opacity-0 group-hover:opacity-100
                    transition-opacity duration-500 pointer-events-none
                  `}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
