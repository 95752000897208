// StatisticsSection.jsx

import React, { useState, useEffect } from "react";
import {
  Users,
  TrendingUp,
  Target,
  Brain,
  Lightbulb,
  Handshake,
} from "lucide-react";

const StatisticsSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [animatedStats, setAnimatedStats] = useState({
    betCreation: 0,
    makingBets: 0,
    predictions: 0,
    tips: 0,
    p2pBetting: 0,
    activeUsers: 0,
  });
  const finalStats = {
    betCreation: 0,
    makingBets: 0,
    predictions: 0,
    tips: 0,
    p2pBetting: 0,
    activeUsers: 0,
  };

  const stats = [
    {
      icon: (
        <Target className="w-6 h-6 text-blue-600 group-hover:scale-110 transition-transform duration-300" />
      ),
      label: "Bet Creation",
      value: animatedStats.betCreation,
      suffix: "+",
    },
    {
      icon: (
        <TrendingUp className="w-6 h-6 text-blue-600 group-hover:scale-110 transition-transform duration-300" />
      ),
      label: "Bettings Made",
      value: animatedStats.makingBets,
      suffix: "+",
    },
    {
      icon: (
        <Brain className="w-6 h-6 text-blue-600 group-hover:scale-110 transition-transform duration-300" />
      ),
      label: "Predictions Made",
      value: animatedStats.predictions,
      suffix: "+",
    },
    {
      icon: (
        <Lightbulb className="w-6 h-6 text-blue-600 group-hover:scale-110 transition-transform duration-300" />
      ),
      label: "Tips Created",
      value: animatedStats.tips,
      suffix: "+",
    },
    {
      icon: (
        <Handshake className="w-6 h-6 text-blue-600 group-hover:scale-110 transition-transform duration-300" />
      ),
      label: "P2P Betting",
      value: animatedStats.p2pBetting,
      suffix: "+",
    },
    {
      icon: (
        <Users className="w-6 h-6 text-blue-600 group-hover:scale-110 transition-transform duration-300" />
      ),
      label: "Active Users",
      value: animatedStats.activeUsers,
      suffix: "k",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    const element = document.getElementById("stats-section");
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const duration = 2000;
      const steps = 60;
      const interval = duration / steps;

      let step = 0;
      const timer = setInterval(() => {
        step++;
        const progress = step / steps;
        const easeOutQuad = 1 - Math.pow(1 - progress, 2);

        setAnimatedStats({
          betCreation: Math.round(finalStats.betCreation * easeOutQuad),
          makingBets: Math.round(finalStats.makingBets * easeOutQuad),
          predictions: Math.round(finalStats.predictions * easeOutQuad),
          tips: Math.round(finalStats.tips * easeOutQuad),
          p2pBetting: Math.round(finalStats.p2pBetting * easeOutQuad),
          activeUsers: Math.round(finalStats.activeUsers * easeOutQuad),
        });

        if (step === steps) {
          clearInterval(timer);
        }
      }, interval);

      return () => clearInterval(timer);
    }
  }, [isVisible]);

  return (
    <section
      id="stats-section"
      className="relative py-24 bg-black overflow-hidden"
    >
      <div className="absolute inset-0">
        <div
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage:
              "linear-gradient(to right, #1d4ed8 1px, transparent 1px), linear-gradient(to bottom, #1d4ed8 1px, transparent 1px)",
            backgroundSize: "64px 64px",
          }}
        />
        <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-blue-500/5 blur-[120px] animate-pulse" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-blue-500/5 blur-[120px] animate-pulse delay-150" />
      </div>

      <div className="container mx-auto px-4 relative z-10 transform scale-75 md:scale-100 origin-top-left">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="group transform hover:scale-[1.02] transition-all duration-300"
              style={{
                animationDelay: `${index * 100}ms`,
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? "translateY(0)" : "translateY(20px)",
                transition: "all 0.6s cubic-bezier(0.4, 0, 0.2, 1)",
              }}
            >
              <div className="bg-gradient-to-br from-black via-blue-950/10 to-black p-6 rounded-xl border border-blue-600/20 hover:border-blue-600/40 transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/10">
                <div className="flex items-center space-x-4 mb-4">
                  <div className="p-2 rounded-lg bg-blue-600/10 group-hover:bg-blue-600/20 transition-colors duration-300">
                    {stat.icon}
                  </div>
                  <p className="text-gray-400 font-medium group-hover:text-gray-300 transition-colors duration-300">
                    {stat.label}
                  </p>
                </div>
                <h3 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-400">
                  {new Intl.NumberFormat().format(stat.value)}
                  <span className="text-blue-600 group-hover:text-blue-500 transition-colors duration-300">
                    {stat.suffix}
                  </span>
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StatisticsSection;
