import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, X, MessageCircle } from "lucide-react";

const NavBar = ({ onContactUsClick }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [scrollState, setScrollState] = useState({
    isScrolled: false,
    isVisible: true,
    lastScrollY: 0,
  });

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    const isScrolled = currentScrollY > 20;

    setScrollState((prev) => ({
      isScrolled,
      isVisible: currentScrollY < prev.lastScrollY || currentScrollY < 100,
      lastScrollY: currentScrollY,
    }));
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleMobileContactClick = (e) => {
    e.preventDefault();
    setIsOpen(false);
    setTimeout(() => {
      onContactUsClick();
    }, 300);
  };

  const navigationItems = [
    { to: "/demo", text: "Demos" },
    { to: "/faq", text: "FAQ" },
  ];

  const isActivePath = (path) => location.pathname === path;

  return (
    <nav
      className={`
        fixed w-full z-50
        transition-transform duration-500 ease-in-out
        ${scrollState.isVisible ? "translate-y-0" : "-translate-y-full"}
        ${
          scrollState.isScrolled
            ? "bg-gradient-to-r from-blue-900 via-black to-blue-900 shadow-lg"
            : "bg-transparent"
        }
      `}
    >
      <div className="container mx-auto px-4">
        <div className="relative flex justify-between items-center h-12 md:h-16">
          <Link
            to="/"
            className={`
              transform transition-transform duration-300 ease-out
              hover:scale-105
            `}
          >
            <img src="/logo2.PNG" alt="Logo" className="h-6 md:h-10" />
          </Link>

          <div className="hidden md:flex items-center space-x-4 md:space-x-6">
            {navigationItems.map(({ to, text }) => (
              <Link
                key={to}
                to={to}
                className={`
                  relative text-xs md:text-sm font-medium
                  transition-all duration-300 ease-out
                  ${
                    isActivePath(to)
                      ? "text-blue-400"
                      : "text-white hover:text-blue-400"
                  }
                  before:absolute before:-bottom-0.5 before:left-0
                  before:w-0 before:h-0.5 before:bg-blue-400
                  before:transition-all before:duration-300
                  hover:before:w-full
                `}
              >
                {text}
              </Link>
            ))}
            <button
              onClick={onContactUsClick}
              className={`
                flex items-center space-x-2 px-2 py-1 md:px-4 md:py-2 rounded-full
                text-xs md:text-sm font-medium
                transition-all duration-300 ease-out
                transform hover:scale-105 active:scale-95
                bg-[#1E88E5] hover:bg-blue-500 text-white
              `}
            >
              <MessageCircle size={16} />
              <span>Contact Us</span>
            </button>
          </div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden p-2 rounded-lg hover:bg-white/10 
              transition-colors duration-300 ease-out"
            aria-label="Toggle menu"
          >
            {isOpen ? (
              <X size={20} className="text-white" />
            ) : (
              <Menu size={20} className="text-white" />
            )}
          </button>
          <div
            className={`
              absolute top-12 left-0 w-full
              transform transition-transform duration-300 ease-in-out
              ${
                isOpen
                  ? "translate-y-0 opacity-100 pointer-events-auto"
                  : "-translate-y-full opacity-0 pointer-events-none"
              }
              md:hidden
              bg-gradient-to-b from-black via-blue-900 to-black
              shadow-xl
            `}
          >
            <div className="p-4 space-y-3">
              {navigationItems.map(({ to, text }) => (
                <Link
                  key={to}
                  to={to}
                  className={`
                    block px-4 py-2 rounded-lg
                    text-sm transition-colors duration-300 ease-out
                    ${
                      isActivePath(to)
                        ? "bg-blue-500/10 text-blue-400"
                        : "text-white hover:bg-white/10"
                    }
                  `}
                  onClick={() => setIsOpen(false)}
                >
                  {text}
                </Link>
              ))}

              <button
                onClick={handleMobileContactClick}
                className="w-full flex items-center justify-center
                  space-x-2 px-4 py-2 rounded-lg 
                  bg-blue-500 hover:bg-blue-600
                  text-white text-sm font-medium
                  transition-all duration-300 ease-out"
              >
                <MessageCircle size={16} />
                <span>Contact Us</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;